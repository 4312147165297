/* 3.2 tf-about-section */

.tf-about-section {
    border-top: 1px solid $border-color;
    position: relative;
    overflow: hidden;

    .tf-about-wrap {
        .tf-about-img {
            text-align: right;
            margin-right: 30px;
            position: relative;
            max-width: 545px;
            z-index: 1;

            @media(max-width:991px) {
                text-align: left;
            }

            img {
                filter: grayscale(1);
            }

            .tf-about-img-text {
                width: 201px;
                height: 235px;
                background: rgba(0, 0, 0, 0.65);
                backdrop-filter: blur(15px);
                border-radius: 8px;
                margin-left: auto;
                margin-top: -200px;
                margin-right: -50px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                @media(max-width:575px) {
                    width: 140px;
                    height: 180px;
                    margin-top: -135px;
                    margin-right: -30px;
                }

                .tf-about-icon {
                    display: block;

                    h3 {
                        font-size: 100px;
                        color: $white;

                        @media(max-width:575px) {
                            font-size: 70px;
                        }
                    }
                }

                span {
                    display: block;
                    max-width: 133px;
                    margin: 0 auto;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 112.5%;
                    text-align: center;
                    text-transform: capitalize;
                    color: $white;

                    @media(max-width:575px) {
                        font-size: 14px;
                    }
                }

            }
        }

        .tf-about-text {
            @media(max-width:1399px) {
                padding-left: 30px;
            }

            @media(max-width:991px) {
                margin-bottom: 30px;
            }

            @media(max-width:991px) {
                padding-left: 0;
                margin-top: 40px;
            }


            h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 64px;
                line-height: 100.8%;
                letter-spacing: 0.01em;
                margin-bottom: 20px;
                color: $white;

                @media(max-width:1399px) {
                    font-size: 55px;
                }

                @media(max-width:1199px) {
                    font-size: 45px;
                }

                @media(max-width:575px) {
                    font-size: 40px;
                }
            }

            h5 {
                font-style: normal;
                font-weight: 300;
                font-size: 30px;
                line-height: 104.8%;
                max-width: 540px;
                margin-bottom: 20px;
                color: #a9a9a9;

                @media(max-width:991px) {
                    max-width: 100%;
                }

                @media(max-width:575px) {
                    font-size: 25px;
                }
            }

            p {
                overflow: hidden;
                font-weight: 300;
                font-size: 20px;
                line-height: 160%;
                max-width: 560px;

                @media(max-width:991px) {
                    max-width: 100%;
                }
            }

            small {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                font-family: $heading-font;
                color: $theme-primary-color;
                padding-left: 15px;
                position: relative;
                display: inline-block;
                margin-bottom: 25px;
                background: $dark-gray;
                z-index: 1;

                &:before {
                    position: absolute;
                    left: 0;
                    top: -7px;
                    height: 39px;
                    width: 39px;
                    content: "";
                    border: 2px solid $theme-primary-color;
                    z-index: -11;
                }

                &:after {
                    position: absolute;
                    left: 34px;
                    top: 3px;
                    height: 20px;
                    width: 20px;
                    content: "";
                    background: $dark-gray;
                    z-index: -1;
                }
            }

            .tf-funfact {
                display: flex;
                justify-content: space-between;
                max-width: 450px;
                margin-top: 30px;

                .tf-funfact-item {
                    h3 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 56px;
                        line-height: 50px;
                        color: $theme-primary-color;

                        @media(max-width:1199px) {
                            font-size: 40px;
                            line-height: 40px;
                        }
                    }

                    p {
                        margin-bottom: 0;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 160%;
                        font-family: $heading-font;

                        @media(max-width:575px) {
                            font-size: 16px;
                        }
                    }
                }

            }
        }

    }
}


.visible-rotate-text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 100%;
    text-align: center;
    opacity: .85;
    transform: translate(-50%, -50%);

    @media(max-width:991px) {
        display: none;
    }

    h1 {
        font-size: 230px;
        letter-spacing: 5px;
        color: #0c0c0c;
        line-height: 200px;
        text-transform: uppercase;
        display: block;
        transform: rotate(-45deg);

        @media(max-width:1399px) {
            font-size: 200px;
            line-height: 150px;
        }

        @media(max-width:1199px) {
            font-size: 150px;
            line-height: 150px;
        }
    }
}


/* 3.6 tp-service-area */

.tp-service-area {
    border-top: 1px solid $border-color;
    position: relative;
    overflow: hidden;

    .tp-service-wrap {
        position: relative;
        z-index: 11;
        box-shadow: 0px 5px 30px rgba(7, 6, 56, 0.08);

        .tp-service-item {
            border-left: 1px solid $border-color;
            transition: all .3s;
            padding: 70px 30px;
            background: $card-bg-color;
            height: 400px;

            @media(max-width:1399px) {
                padding: 60px 15px;
            }

            @media(max-width:991px) {
                padding: 60px 40px;
                border-bottom: 1px solid $border-color;
            }

            @media(max-width:767px) {
                padding: 40px 15px;
            }


            .fi {
                position: relative;

                &:after {
                    position: absolute;
                    left: 20px;
                    top: -40px;
                    width: 55px;
                    height: 55px;
                    background: rgba(177, 151, 119, 0.08);
                    content: "";
                    border-radius: 50%;
                    transition: all .3s;
                }

                &:before {
                    font-size: 50px;
                    color: $theme-primary-color;
                    transition: all .3s;
                }
            }

            h2 {
                font-weight: 400;
                font-size: 30px;
                line-height: 33px;
                margin-top: 20px;
                margin-bottom: 20px;
                cursor: pointer;
                transition: all .3s;

                @media(max-width:1199px) {
                    font-size: 24px;
                    margin-bottom: 10px;
                }

                &:hover {
                    color: $theme-primary-color;
                }
            }

            p {
                font-weight: 300;
                font-size: 17px;
                line-height: 160%;
                margin-bottom: 0;
                transition: all .3s;
            }

            .read-more {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 0;
                background: rgba(90, 90, 90, 0.24);
                text-align: center;
                position: relative;
                margin-top: 20px;
                border: 0;
                padding: 0;

                &:hover {
                    background: $theme-primary-color;
                }

                .fi {
                    position: absolute;
                    transform: rotate(45deg);
                    left: 11px;
                    color: $white;

                    &:after {
                        display: none;
                    }

                    &:before {
                        font-size: 18px;
                        color: $white;
                    }
                }
            }
        }

        .col {
            padding: 0;

            @media(max-width:767px) {
                padding: 0 15px;
            }

            &:first-child {
                .tp-service-item {
                    border-left: 0;
                }
            }

            &:nth-child(3) {
                @media(max-width:991px) {
                    .tp-service-item {
                        border-left: 0;
                    }
                }
            }

            @media(max-width:767px) {
                .tp-service-item {
                    border-left: 0;
                }
            }

            &:hover {
                .tp-service-item {
                    background: #181818;

                    .fi {
                        &:before {
                            color: $white;
                        }

                        &:after {
                            background: rgba(255, 255, 255, 0.08);
                        }
                    }

                    h2 {
                        a {
                            color: $white;

                            &:hover {
                                color: $theme-primary-color;
                            }
                        }
                    }

                    p {
                        color: $white;
                    }
                }
            }
        }

    }
}


// digital-marque-sec

.digital-marque-sec {
    background: $card-bg-color;
    padding: 40px 0;
    position: relative;
    z-index: -1;
    overflow: hidden;

    @media(max-width:991px) {
        padding: 20px 0;
    }

    .digital-marque {
        position: relative;
        left: -10%;
        width: 120%;
        z-index: -1;
        overflow: hidden;

        .track {
            animation: marquee 200s linear infinite;
        }

        @keyframes marquee {
            from {
                transform: translateX(0);
            }

            to {
                transform: translateX(-50%);
            }
        }

        h1 {
            font-weight: 400;
            font-size: 48px;
            line-height: 102%;
            white-space: nowrap;
            will-change: transform;
            animation: marquee 20s linear infinite;
            margin-bottom: 0;
            color: $white;

            @media(max-width:991px) {
                font-size: 30px;
            }

            @media(max-width:575px) {
                font-size: 20px;
                animation: marquee 10s linear infinite;
            }
        }
    }
}

/* tp-project-section */

.tp-project-section {
    position: relative;
    z-index: 11;
    border-bottom: 1px solid $border-color;
    overflow: hidden;

    .project-btn {
        text-align: center;
    }

    .tp-project-wrap {
        .tp-project-item {
            margin-bottom: 40px;
            border: 1px solid $border-color;

            .tp-project-content {
                padding: 25px 45px;

                @media(max-width:991px) {
                    padding: 20px 30px;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 153.8%;
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.63);
                }

                h2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 103.3%;
                    margin-top: 8px;
                    cursor: pointer;
                    transition: all .3s;

                    &:hover {
                        color: $theme-primary-color;
                    }

                    @media(max-width:991px) {
                        font-size: 20px;
                    }
                }
            }

            .tp-project-img {
                overflow: hidden;
                cursor: pointer;

                img {
                    width: 100%;
                    transition: all .3s;
                    transform: scale(1);
                }
            }

            &:hover {
                .tp-project-img {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }


    }
}


// tp-contact-form-area

.tp-contact-form-area {
    position: relative;
    overflow: hidden;
    border-top: 1px solid $border-color;

    .tp-contact-form-wrap {
        padding: 85px 130px;
        background: $card-bg-color;
        box-shadow: 0px 15px 25px rgba(2, 4, 23, 0.08);

        @media(max-width:991px) {
            padding: 35px 50px;
        }

        @media(max-width:575px) {
            padding: 30px 20px;
        }


        .form-group {
            margin-bottom: 20px;
        }

        label {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            margin-bottom: 5px;
        }

        form input,
        form select,
        form textarea {
            background: transparent;
            border: 1px solid rgba(150, 154, 166, 0.48);
            width: 100%;
            height: 56px;
            border-radius: 0px;
            box-shadow: none !important;
            padding-left: 25px;

            @include media-query(991px) {
                height: 45px;
            }

            &:focus {
                border-color: $theme-primary-color;
                background: $white;
            }
        }

        form textarea {
            height: 107px;
            padding-top: 15px;
        }

        .submit-area {
            margin-top: 32px;
            @media(max-width:767px) {
                text-align: center;
            }
        }

        .contact-info {
            ul {
                list-style: none;
                display: flex;
                margin-top: 40px;

                @media(max-width:767px) {
                    margin-top: 0px;
                    justify-content: center;
                }

                li {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 160%;

                    @media(max-width:575px) {
                        font-size: 12px;
                    }

                    i {
                        width: 29px;
                        height: 29px;
                        line-height: 29px;
                        background: transparentize($theme-primary-color, .8);
                        border-radius: 50%;
                        display: inline-block;
                        font-size: 14px;
                        text-align: center;
                        color: $theme-primary-color;
                        margin-right: 5px;
                        border: 1px solid $theme-primary-color;
                    }

                    &+li {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

/* 3.8 tp-testimonial-section */

.tp-testimonial-section {
    position: relative;
    overflow: hidden;

    .tp-testimonial-wrap {
        .slick-slide {
            padding: 0 15px;
        }
    }

    .tp-testimonial-item {
        padding-bottom: 20px;
        padding: 30px 20px;
        background: $card-bg-color;

        .tp-testimonial-text {
            p {
                font-style: italic;
                font-weight: 400;
                font-size: 20px;
                line-height: 154.5%;
                font-family: $heading-font;
                margin-bottom: 30px;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 149.5%;
                text-transform: uppercase;
                color: $light;
                position: relative;
                padding: 20px 0;
                padding-left: 40px;
                padding-right: 100px;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 22px;
                    height: 1px;
                    background: $light;
                    content: "";
                }

                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "\f117";
                    font-family: "flaticon";
                    font-size: 55px;
                }
            }
        }
    }

    .owl-nav {
        display: none;
    }
}

/* 3.7 tp-blog-section */

.tp-blog-section {
    position: relative;
    z-index: 1;
    border-top: 1px solid $border-color;
    overflow: hidden;

    @media(max-width:767px) {
        padding-bottom: 60px;
    }

    .tp-blog-item {
        margin-bottom: 30px;
        padding: 20px;
        height:700px;
        border: 1px solid $border-color;

        .tp-blog-img {
            overflow: hidden;
            position: relative;

            .thumb {
                position: absolute;
                left: 15px;
                top: 15px;
                padding: 7px 25px 6px;
                background: $theme-primary-color;
                color: $white;
                text-transform: uppercase;
                font-size: 14px;

            }

            img {
                width: 100%;
                -webkit-filter: grayscale(0);
                -moz-filter: grayscale(0);
                -o-filter: grayscale(0);
                -ms-filter: grayscale(0);
                filter: grayscale(0);
                transition: all .3s;
                transform: scale(1);
            }
        }

        &:hover {
            .tp-blog-img {
                img {
                    -webkit-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    filter: grayscale(100%);
                    transform: scale(1.2);
                }
            }
        }


        .tp-blog-content {
            padding-top: 20px;

            ul {
                list-style: none;
                display: flex;
                margin-bottom: 15px;

                li {
                    color: $theme-primary-color;
                    font-size: 16px;

                    &:first-child {
                        padding-right: 30px;
                        position: relative;

                        &:before {
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 6px;
                            height: 6px;
                            content: "";
                            background: $theme-primary-color;
                            border-radius: 50%;
                        }
                    }

                    a {
                        color: $theme-primary-color;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }
            }

            h2 {
                font-size: 25px;
                font-family: $heading-font;
                margin-bottom: 20px;
                line-height: 35px;
                font-weight: 600;
                margin-top: 10px;
                cursor: pointer;

                @media(max-width:1500px) {
                    font-size: 22px;
                    margin-bottom: 10px;
                }

                @media(max-width:1200px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            p {
                font-size: 18px;
                color: $text-color;
            }

        }
    }
}


/* 6.4 tp-pricing-section */

.tp-pricing-section {
    border-top: 1px solid $border-color;
    position: relative;
    overflow: hidden;
    .tp-pricing-wrap {
        .tp-pricing-item {
            background: $card-bg-color;
            box-shadow: 0px 2px 16.8px 3.2px rgba(0, 38, 66, 0.08);

            @media(max-width:991px) {
                margin-bottom: 30px;
            }

            .tp-pricing-top {
                padding: 30px;
                text-align: center;
                padding-top: 0;
                padding-bottom: 0;

                .pricing-thumb {
                    display: inline-block;
                    padding: 3px 30px 5px;
                    background: #19181d;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    span {
                        text-transform: uppercase;
                        color: $white;
                    }
                }


                .tp-pricing-text {
                    margin-top: 30px;

                    h2 {
                        color: $white;
                        font-size: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 15px;
                        font-weight: 700;

                        span {
                            font-weight: 500;
                            color: $text-color;
                            font-size: 16px;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        padding-bottom: 30px;
                        max-width: 282px;
                        margin: 0 auto;
                        border-bottom: 1px solid $border-color;
                    }
                }
            }

            .tp-pricing-bottom {
                padding: 40px;
                padding-top: 35px;
                text-align: center;

                .tp-pricing-bottom-text {
                    ul {
                        list-style: none;

                        li {
                            color: $light;
                            padding-bottom: 15px;
                        }
                    }

                    a {
                        font-size: 16px;
                        color: $theme-primary-color;
                        font-weight: 500;
                        display: inline-block;
                        margin-top: 20px;
                        position: relative;
                        padding: 10px 25px;
                        border: 1px solid $theme-primary-color;

                        &:hover {
                            background: $theme-primary-color;
                            color: $white;

                            &:before {
                                background-color: $theme-primary-color;
                            }
                        }
                    }
                }
            }
        }

        .col {
            &:last-child {
                .tp-pricing-item {
                    margin-bottom: 0;
                }
            }
        }
    }

    .invisible-title1 {
        display: none;
    }
}